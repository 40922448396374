import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container text-container full" }
const _hoisted_2 = { class: "flex-vgap" }

import { useSeoMeta } from '@unhead/vue'
import BlogPageList from './BlogPageList.vue'

const title = 'Blog'
const description = "I sometimes write about interesting stuff I've encountered in life"

export default /*@__PURE__*/_defineComponent({
  __name: 'BlogPage',
  setup(__props) {

useSeoMeta({
    title,
    description,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(title)),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(BlogPageList)
    ])
  ]))
}
}

})